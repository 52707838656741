import React from 'react';
import StandardLayout from '../../layouts/standard';
import pdf2 from '../../assets/stylam-exclusive.pdf';

export default function StylamExclusive(props) {
    return <StandardLayout title="Stylam Exclusive">
        <div className="uk-section uk-container uk-margin">
        <embed src={pdf2} width="100%" style={{height: "80vh"}} type="application/pdf"/>
        </div>
    </StandardLayout>
}